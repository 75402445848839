<template>
  <div class="dashboard-right">
    <div class="dashboard-company-img">
      <div class="image-profile">
        <img :src="companyProfile.url_Logo" alt="" />
        <div v-show="false" class="default-icon">
          <i class="fas fa-building"></i>
        </div>
      </div>
      <div class="detail-company">
        <h1 class="text-center">{{ companyProfile.name }}</h1>
        <p>{{ companyProfile.typeBusiness }}</p>
      </div>
    </div>
    <div class="company-description">
      <v-row dense>
        <v-col cols="12" sm="12" md="12" lg="12">
          <div class="card-company">
            <span class="bar-left"></span>
            <h2>Email</h2>
            <p>{{ companyProfile.email }}</p>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12">
          <div class="card-company">
            <span class="bar-left"></span>
            <h2>Contact</h2>
            <p>{{ companyProfile.phone }}</p>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12">
          <div class="card-company">
            <span class="bar-left"></span>
            <h2>Address</h2>
            <p>{{ companyProfile.address }}</p>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12">
          <div class="card-company">
            <span class="bar-left"></span>
            <h2>Founding Date</h2>
            <p>{{ companyProfile.founding_date }}</p>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companyProfile: {},
    };
  },
  methods: {
    fetchCompanyProfile() {
      this.$axios.get(`company/my-profile`).then((res) => {
        if (res.status === 200) {
          this.companyProfile = res.data.data;
        }
      });
    },
  },
  created() {
    this.fetchCompanyProfile();
  },
};
</script>

<style scoped>
</style>
